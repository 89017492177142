:root{
    --primary: #000;
    --light_grey: #F2F2F2;
    --grey: #808080;
    --grey2: #DDDDDD;
    --logoHeight: auto;
    --logoWidth: 250px;
    --navy: #000;
    --light_navy: #000;
		--black1: rgba(0, 0, 0, 0.87);
		--white:#FFFFFF;
    --black2: #393939;
		--skyblue:#4FAADB;
    --red:#E04D43;
    --yellow:#FCF200;
    --green:#76D33E;
    --grad1: #000;
    --grad2: #000;
        /*TAKE SAME COLOR AS LIGHT_NAVY AND CONVERT https://www.rapidtables.com/convert/color/hex-to-rgb.html */
    --shadowColor: 79, 170, 219;

    --logoHeight: auto;
    --logoWidth: 250px;

     /*TAKE SAME COLOR AS PRIMARY AND CONVERT https://angel-rs.github.io/css-color-filter-generator/ */
    --primaryfilter: brightness(0);
}
